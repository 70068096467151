<template>
  <div class="pbf">
     <BrandTabProducts
       :inurl="`/${props.type}/${props.id}/products`"
       :brand-id="props.id"
        :intags="{}"
      />
  </div>
</template>

<script setup lang="ts">

interface Props {
  id: number
  type: string
}

const props = defineProps<Props>()

</script>

<style scoped>


</style>
